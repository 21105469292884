<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
import { onMount } from "svelte";
import { ClipPath, Polygon, Svg, SVG } from "@svgdotjs/svg.js";

class OctaCardElement {
    private _svg: Svg;
    private _polygon: Polygon;
    private _rootElement: HTMLElement;
    private _clipElement: HTMLElement;
    private _observer: ResizeObserver;
    private _bevelSize: number;
    private _thickness: number;
    private _color: string;

    public constructor(
        rootElement: HTMLElement,
        clipElement: HTMLElement,
        bevelSize: number,
        thickness: number,
        color: string
    ) {
        this._bevelSize = bevelSize;
        this._thickness = thickness;
        this._color = color;
        this._rootElement = rootElement;
        this._clipElement = clipElement;

        this._svg = SVG().addTo(rootElement);
        this._polygon = this._svg.polygon().fill('none').stroke({ opacity: 1 });
        this._observer = new ResizeObserver(this.repaint.bind(this));
        this._observer.observe(rootElement);
    }

    public repaint(): void {
        const thickness = this._thickness;
        const color = this._color;
        const path = this.getOctaPath();

        this._polygon
            .stroke({
                width: thickness * 2, // 半分はみ出してるのを倍にしてクリップしている
                color: color
            }).attr({ 
                points: path,
            });

        // pxをつけてclip-pathにセットする
        this._clipElement.style.setProperty('--clip-path', `polygon(${this.addPxToNumberStrs(path)})`);
    }

    private getOctaPath(): string {
        const edgeSize = this._bevelSize;
        const xmin = 0;
        const xmax = this._rootElement.clientWidth;
        const ymin = 0;
        const ymax = this._rootElement.clientHeight;
        const xoppo = xmax - edgeSize;
        const yoppo = ymax - edgeSize;

        return `${edgeSize} ${ymin}, ${xoppo} ${ymin}, ${xmax} ${edgeSize}, ${xmax} ${yoppo}, ${xoppo} ${ymax}, ${edgeSize} ${ymax}, ${xmin} ${yoppo}, ${xmin} ${edgeSize}`;
    }

    private addPxToNumberStrs(str: string): string {
        return str.replace(/(\d+)/g, (match, p1) => `${p1}px`);
    }
}

export let bevelSize: number;
export let thickness: number;
export let color: string;
let cardRoot: HTMLElement;
let cardContent: HTMLElement;

onMount(() => {
    if (cardRoot && cardContent)
    {
        const octaCardElement = new OctaCardElement(
            cardRoot, 
            cardContent, 
            bevelSize, 
            thickness, 
            color
        );
    }
});
</script>

<div class="octa-card-root" bind:this={cardRoot}>
    <div class="octa-card-content" bind:this={cardContent}>
        <slot></slot>
    </div>
</div>

<style lang="scss">
.octa-card-root {
    position: relative;
    width: 100%;
    height: 100%;

    :global(> svg) {
        overflow: visible;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }
}

.octa-card-content {
    width: 100%;
    height: 100%;
    clip-path: var(--clip-path);
}
</style>