export default {
    colorLightBg: "#F1EBCF",
    colorLightBgGrey: "#F0F0F0",
    colorYellowBg: "#F7F5EA",
    colorAccent: "#A8953E",
    colorAccentLight: "#beb070",
    colorAccentYellow: "#E9ED7D",
    colorDarkBrown: "#351C11",
    colorWarning: "#933030",
    colorWarningBg: "#F4E9E9",
    colorBorderGrey: "#ADABAB",
    colorOk: "#E3FDBE",
    colorHeaderFont: "#47400E",
    colorBasicFont: "#515151",
    colorBasicLightFont: "#666666",
    colorBasicLighterFont: "#999999",
};